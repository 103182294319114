<template>
  <div class="py-10 space-y-10">
    <NSeparator separator-position="right">
      <NAvatar
        size="lg"
        src="https://avatars.githubusercontent.com/u/28706372?v=4"
      />
    </NSeparator>
    <NSeparator separator-position="center">
      <NAvatar
        size="lg"
        src="https://avatars.githubusercontent.com/u/499550?v=4"
      />
    </NSeparator>
    <NSeparator separator-position="left">
      <NAvatar
        size="lg"
        src="https://avatars.githubusercontent.com/u/11247099?v=4"
      />
    </NSeparator>
  </div>
</template>
