<template>
  <NAlert
    alert="outline-info"
    icon
  >
    <template #title>
      <div class="flex-1 md:flex md:justify-between space-y-3 md:space-y-0">
        <p class="text-brand">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus vel optio aliquam autem dolorem enim! Quo, nesciunt deleniti. Natus quisquam quae quis quam ipsa reiciendis at alias molestiae voluptate repudiandae.
        </p>
        <NButton
          btn="text-info"
          to="#"
          label="i-heroicons-chevron-double-right"
          icon
          class="animate-pulse p-0 md:ml-4"
        />
      </div>
    </template>
  </NAlert>
</template>
