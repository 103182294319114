<template>
  <div class="h-28 flex items-center justify-around">
    <NTooltip
      content="The xs size"
      size="xs"
    >
      <NButton btn="solid-white">
        XS
      </NButton>
    </NTooltip>
    <NTooltip
      content="The sm size"
      size="sm"
    >
      <NButton btn="solid-white">
        SM
      </NButton>
    </NTooltip>
    <NTooltip
      content="The md size"
      size="md"
    >
      <NButton btn="solid-white">
        MD
      </NButton>
    </NTooltip>
    <NTooltip
      content="The lg size"
      size="lg"
    >
      <NButton btn="solid-white">
        LG
      </NButton>
    </NTooltip>
  </div>
</template>
