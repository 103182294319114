<template>
  <div class="flex items-center gap-4">
    <NIndicator indicator="top-right solid-error" ping>
      <NAvatar label="TR" />
    </NIndicator>

    <NIndicator indicator="bottom-right solid-info" ping>
      <NAvatar label="BR" />
    </NIndicator>

    <NIndicator indicator="top-left solid-warning" ping>
      <NAvatar label="TL" />
    </NIndicator>

    <NIndicator indicator="bottom-left solid-success" ping>
      <NAvatar label="BL" />
    </NIndicator>
  </div>
</template>
