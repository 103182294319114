<template>
  <div class="h-28 flex flex-wrap items-center justify-around gap-4">
    <NTooltip
      tooltip="black"
      content="The color is black"
    >
      <NButton
        btn="solid-black"
        label="Black"
      />
    </NTooltip>

    <NTooltip
      tooltip="white"
      content="The color is white"
    >
      <NButton
        btn="solid-white"
        label="White"
      />
    </NTooltip>

    <NTooltip
      tooltip="gray"
      content="The color is gray"
    >
      <NButton
        btn="solid-gray"
        label="Gray"
      />
    </NTooltip>

    <NTooltip
      tooltip="primary"
      content="The color is primary"
    >
      <NButton
        btn="solid-primary"
        label="Primary"
      />
    </NTooltip>

    <NTooltip
      tooltip="error"
      content="The color is error"
    >
      <NButton
        btn="solid-error"
        label="Error"
      />
    </NTooltip>

    <NTooltip
      tooltip="warning"
      content="The color is warning"
    >
      <NButton
        btn="solid-warning"
        label="Warning"
      />
    </NTooltip>
  </div>
</template>
