<template>
  <div class="flex flex-wrap items-start gap-4">
    <NButton
      label="Button"
      square
      btn="solid-orange"
    />

    <NButton
      label="Button"
      square="16"
      btn="solid-lime"
    />

    <NButton
      label="Button"
      square="24"
      btn="solid-pink"
    />

    <NButton
      label="Button"
      square="32"
      btn="solid-purple"
    />

    <NButton
      label="Button"
      square="40"
      btn="solid-red"
    />
  </div>
</template>
