<template>
  <NButton
    loading
    label="Button"
  >
    <template #loading>
      <span class="animate-pulse rounded-full bg-base">
        <img
          class="h-5"
          src="https://avatars.githubusercontent.com/u/33350692?s=400&u=49395c835e8197ae2ee42ca02c95e828d8f64239&v=4"
        >
      </span>
    </template>
  </NButton>
</template>
