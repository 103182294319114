<template>
  <NButton btn="outline" class="rounded-full" label="View">
    <template #leading>
      <!-- TODO convert to NAvatar soon -->
      <span class="rounded-full bg-base">
        <img
          class="h-5"
          src="https://avatars.githubusercontent.com/u/33350692?s=400&u=49395c835e8197ae2ee42ca02c95e828d8f64239&v=4"
        >
      </span>
    </template>
  </NButton>
</template>
