<template>
  <NSeparator
    separator="solid-error"
    label="Warning Area"
  />
  <NSeparator
    separator="dashed-success"
    label="Success Green"
  />
  <NSeparator
    separator="dotted-warning"
    label="Dotted Yellow"
  />
</template>
