<template>
  <div class="flex items-center gap-4">
    <NIndicator indicator="solid-green">
      <NAvatar src="/images/avatar.png" alt="Phojie Rengel" />
    </NIndicator>

    <NIndicator indicator="solid-gray bottom-right">
      <NAvatar src="/images/avatar.png" alt="Phojie Rengel" />
    </NIndicator>

    <NIndicator label="1" indicator="solid-red top-right">
      <NAvatar icon="i-heroicons-phone-arrow-down-left-20-solid" />
    </NIndicator>

    <NIndicator indicator="solid-info" label="New">
      <NAvatar src="/images/avatar.png" alt="Phojie Rengel" />
    </NIndicator>
  </div>
</template>
