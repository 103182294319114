<template>
  <div class="flex items-center gap-4">
    <NAvatar
      src="https://test.com/dummy.png"
      fallback="/images/avatar.png"
    />

    <NAvatar
      src="https://test.com/dummy.png"
      label="DS"
    />

    <NAvatar
      src="https://test.com/dummy.png"
      alt="Dummy Src"
    />

    <NAvatar
      src="https://test.com/dummy.png"
    />
  </div>
</template>
