<template>
  <div class="flex flex-wrap gap-4">
    <NKbd kbd="solid-lime" label="⌘" />

    <NKbd kbd="solid-rose" label="⌘" />

    <NKbd kbd="outline-pink" label="⌘" />

    <NKbd kbd="outline-orange" label="⌘" />
  </div>
</template>
