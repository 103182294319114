<template>
  <div class="flex flex-col">
    <NLink to="#sponsors" class="cursor-pointer">
      <h2 id="sponsors" class="text-4xl font-bold">
        Sponsors
      </h2>
    </NLink>

    <div class="py-4 text-center">
      <a href="https://cdn.jsdelivr.net/gh/phojie/static/sponsors.svg" target="_blank" rel="noopener noreferrer">
        <img
          crossorigin="anonymous"
          inline-block
          class="h-auto max-w-4xl w-full"
          loading="lazy"
          src="https://cdn.jsdelivr.net/gh/phojie/static/sponsors.svg"
          alt="Anthony Fu's sponsors"
        >
      </a>
    </div>
  </div>
</template>
