<template>
  <NAvatarGroup
    size="xs"
    :max="3"
  >
    <NAvatar src="https://avatars.githubusercontent.com/u/11247099?v=4" alt="Anthony Fu" />
    <NAvatar src="https://avatars.githubusercontent.com/u/28706372?v=4" alt="Daniel Roe" />
    <NAvatar src="/images/avatar.png" alt="Phojie Rengel" />
    <NAvatar label="SR" />
  </NAvatarGroup>
</template>
