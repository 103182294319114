<template>
  <div class="flex items-center gap-4">
    <NAvatar
      size="xs"
      src="/images/avatar.png"
    />

    <NAvatar
      size="lg"
      src="/images/avatar.png"
    />

    <NAvatar
      size="2xl"
      src="/images/avatar.png"
    />

    <NAvatar
      size="39px"
      src="/images/avatar.png"
    />

    <NAvatar
      size="sm:3xl md:4xl lg:5xl"
      src="/images/avatar.png"
    />
  </div>
</template>
