<template>
  <div class="flex flex-wrap items-center gap-4">
    <NIcon name="i-heroicons-bell" />

    <NIcon
      name="i-logos-adobe-illustrator"
      size="2xl"
      class="text-primary"
    />

    <NIcon
      name="i-logos-adobe-xd"
      size="2xl"
      class="text-primary"
    />

    <NIcon
      name="i-logos-linux-tux"
      size="6xl"
    />

    <NIcon
      name="i-logos-airbnb"
      size="6xl"
    />

    <NIcon name="i-heroicons-sun-20-solid dark:i-heroicons-moon-20-solid" />

    <NIcon
      name="i-twemoji-black-heart hover:i-twemoji-beating-heart"
      size="hover:xl"
    />

    <NIcon name="i-twemoji-backhand-index-pointing-left" />

    <span>
      Hover
    </span>
  </div>
</template>
