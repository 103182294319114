<template>
  <div class="grid h-28 place-items-center">
    <NTooltip
      content="Hello from tooltip"
    >
      <NButton btn="solid-white">
        Hover me
      </NButton>
    </NTooltip>
  </div>
</template>
