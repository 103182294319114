<template>
  <div class="flex flex-wrap items-center gap-4">
    <NSwitch
      size="xs"
    />

    <NSwitch
      size="lg"
    />

    <NSwitch
      size="xl"
    />

    <NSwitch
      size="2xl"
    />

    <NSwitch
      size="3xl"
    />
  </div>
</template>
