<template>
  <div class="w-250px flex flex-col space-y-3">
    <NSkeleton
      class="h-125px"
    />
    <div class="space-y-2">
      <NSkeleton class="h-4" />
      <NSkeleton class="h-4 w-80%" />
    </div>
  </div>
</template>
