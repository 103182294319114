<template>
  <div class="grid gap-4 sm:cols-2">
    <NInput
      disabled
      placeholder="You can't click here (disabled)"
    />

    <NInput
      readonly
      placeholder="You can't type here (readonly)"
    />
  </div>
</template>
